<template>
  <div class="ship-detail" style="padding:20px 20px; margin-top:0px; background-color:#ffffff; border-radius: 10px;">
    <div class="my-3 flex flex-wrap justify-between gap-2">
      <span v-if="pageStatus == 1">{{ requestData.shipName }}</span>
      <span v-else>Create a New Ship</span>
      <div>
        <div v-if="showBtn">
          <el-button size="mini" @click="handleCloseClick">Close</el-button>
          <el-button size="mini" type="primary" v-if="submitToTerminalBtn" @click="handleStatusButtonClick(0)">Submit To Terminal Approval</el-button>
          <el-button size="mini" type="primary" v-if="resubmitChangesBtn" @click="handleStatusButtonClick(1)">Resubmit Changes</el-button>
          <el-button size="mini" type="primary" v-if="submitBtn" @click="confirmToSubmitDialog = true">Submit</el-button>
          <el-button size="mini" type="success" v-if="acceptBtn" @click="handleStatusButtonClick(6)">Accept</el-button>
          <el-button size="mini" type="danger"  v-if="rejectBtn" @click="handleStatusButtonClick(5)">Reject</el-button>
          <el-button size="mini" type="primary" v-if="submitToInspectionBtn" @click="handleStatusButtonClick(2)">Submit for Inspection</el-button>
          <el-button size="mini" type="primary" v-if="anchorageInspectionBtn" @click="handleStatusButtonClick(4)">Anchorage Inspection</el-button>
          <el-button size="mini" type="primary" v-if="moreInfoRequiredBtn" @click="logisticsRemarksDialog = true">More Information Required</el-button>
          <el-button size="mini" type="primary" v-if="suspendedBtn" @click="suspendedDialog = true">Suspend</el-button>
          <el-button size="mini" type="danger" v-if="deleteBtn" @click="confirmToDeleteDialog = true">Delete</el-button>
        </div>
        <div v-else="!showBtn">
          <el-button size="mini" @click="handleCloseClick">Close</el-button>
          <span style="color: #57a2de; font-size: 12px;padding-left: 10px;" v-html="showStatus"></span>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Ship Information" name="informationTab">
      </el-tab-pane>
      <el-tab-pane label="History Record" name="historyTab" v-if="pageStatus == 1">
      </el-tab-pane>
    </el-tabs>
    <ShipVettingHistory 
      v-show="activeTab == 'historyTab'" 
      :imo="imo"
      :key="imo + historyKey"
    />
    <ShipVettingInformation 
      v-show="activeTab == 'informationTab'" 
      ref="shipVettingForm" 
      :requestData="requestData"
      @get-list="getShipVettingReqeustDetail"
    />

    <el-dialog title="Confirm To Submit" :visible.sync="confirmToSubmitDialog" :width="isMobileScreen ? '100%' : '500px'">
      <div>
        Once submitted, the request cannot be modified.
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="confirmToSubmitDialog = false">Cancel</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(1)">Submit</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog title="Confirm To Delete" :visible.sync="confirmToDeleteDialog" :width="isMobileScreen ? '100%' : '500px'">
      <div>
        Are you sure you want to delete this request?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="confirmToDeleteDialog = false">Cancel</el-button>
          <el-button type="danger" size="mini" @click="handleDelete()">Delete</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog title="More Information Required" :visible.sync="logisticsRemarksDialog" :width="isMobileScreen ? '100%' : '500px'" @close="resetLogisticsRemarksForm()">
      <div>
        <el-form label-width="auto" size="mini" label-position="top" ref="remarksForm">
          <el-form-item label="Please state the remark:" prop="logisticsRemarks" >
            <el-input type="textarea" :autosize="{ minRows: 3}" v-model="logisticsRemarks" clearable size="small" :style="{ width: '100%' }" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="resetLogisticsRemarksForm()">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(8)">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog title="Suspend Ship" :visible.sync="suspendedDialog" :width="isMobileScreen ? '100%' : '500px'" @close="resetSuspendedForm()">
      <div>
        <el-form label-width="auto" size="mini" label-position="left" ref="suspendedForm">
          <el-form-item label="Start Date" prop="suspendedStartDate" >
            <el-date-picker
            v-model="suspendedStartDate" 
            placeholder="Start Date"
            type="datetime" 
            format="yyyy-MM-dd HH:mm:ss" 
            value-format="yyyy-MM-dd HH:mm:ss"
            :style="{ width: isMobileScreen ? '100%' : '300px' }"
            clearable/>
          </el-form-item>
          <el-form-item label="End Date" prop="suspendedEndDate" >
            <el-date-picker 
            v-model="suspendedEndDate" 
            placeholder="End Date"
            type="datetime" 
            format="yyyy-MM-dd HH:mm:ss" 
            value-format="yyyy-MM-dd HH:mm:ss"
            default-time="23:59:59"
            :style="{ width: isMobileScreen ? '100%' : '300px' }"
            clearable/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="resetSuspendedForm()">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(10)">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div> 
</template>
  
<script>
import tabs from "@/components/tabs.vue";
import { getShipVettingRequestData, deleteShipVettingRequest } from "@/services";
import PdfView from "@/components/common/pdf-view.vue";
import { mapState } from "vuex";
import ShipVettingHistory from "./history.vue";
import ShipVettingInformation from "./information.vue"
import { getShipVettingStatus } from "@/utils/constants";

export default {
  name: "ShipVettingDetail",
  components: { tabs, PdfView, ShipVettingInformation, ShipVettingHistory },

  data() {
    return {
      activeTab: "informationTab",
      // activeTab: "historyTab",
      historyKey: 0,
      id:"",
      pageStatus: "", // page mode
      requestStatus: 0,
      // upIndex: 0,
      imo: "",
      shipName: "",
      requestData: {},
      confirmToSubmitDialog: false,
      confirmToDeleteDialog: false,
      logisticsRemarksDialog: false,
      logisticsRemarks: "",
      // subbtns: false,
      showBtn: true,
      showStatus: "",
      submitToTerminalBtn: false,
      resubmitChangesBtn: false,
      acceptBtn: false,
      rejectBtn: false,
      submitToInspectionBtn: false,
      anchorageInspectionBtn: false,
      moreInfoRequiredBtn: false,
      suspendedBtn: false,
      submitBtn: false,
      deleteBtn: false,
      suspendedDialog: false,
      suspendedStartDate: null,
      suspendedEndDate: null,
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
    this.getShipVettingReqeustDetail();
  },
  mounted() {
    const { id, pageStatus } = this.$route.query;
    this.id = id;
    this.pageStatus = pageStatus;
    // this.getShipVettingReqeustDetail();
  },
  methods: {
    getShipVettingStatus,
    getShipVettingReqeustDetail(){
      this.logisticsRemarks = "";
      if (this.$route.query.pageStatus == 2){
        this.showButtons();
        return;
      }
      getShipVettingRequestData(this.$route.query.id).then((res) => {
        if(res.code == 200){
          this.requestData = res.data
          this.imo = res.data.imo
          this.historyKey += 1; // Increment the key to force re-render
          this.showButtons();
        }
      });
    },
    showButtons(val) {
      const requestStatus = this.requestData.shipStatus;
      const pageStatus = this.$route.query.pageStatus;
      const isLogistics = this.userInfo.role == 1;
      const isCustomer = this.userInfo.role == 4 || this.userInfo.role == 5;
      const isInspector = this.userInfo.role == 13;

      // create new request
      if (pageStatus == 2){
        if (isLogistics){
          this.acceptBtn = true;
          this.rejectBtn = true;
          this.submitToInspectionBtn = true;
          this.anchorageInspectionBtn = true;
          this.moreInfoRequiredBtn = true;
          this.suspendedBtn = true;
        }
        if (isCustomer){
          this.submitToTerminalBtn = true;
        }
        return;
      }

      // modify request
      if (isLogistics){
        if (requestStatus == 1 || requestStatus == 3){
          this.showBtn = true;
          this.acceptBtn = true;
          this.rejectBtn = true;
          this.anchorageInspectionBtn = true;
          this.moreInfoRequiredBtn = true;
          this.suspendedBtn = true;
          if (requestStatus == 1){
            this.submitToInspectionBtn = true;
          }
        }else{
          this.handleShowStatus();
        } 
      }
      if (isCustomer){
        if (requestStatus == 1){
          this.showBtn = true;
          this.resubmitChangesBtn = true;
          this.submitBtn = false;
          this.deleteBtn = true;
        }else if (requestStatus == 7 || requestStatus == 8){
          this.showBtn = true;
          this.resubmitChangesBtn = false;
          this.submitBtn = true;
          this.deleteBtn = false;
        }else{
          this.handleShowStatus();
        }
      }
      if (isInspector){
        if (requestStatus == 2 || requestStatus == 4){
          this.submitBtn = true;
        }else{
          this.handleShowStatus();
        }
      }
    },
    handleShowStatus(){
      this.showBtn = false;
      this.showStatus = getShipVettingStatus(this.requestData.shipStatus);

      if(this.requestData.shipStatus === 10){
        const sus = this.showStatus;
        const susStart = this.requestData.suspendedStart;
        const susEnd = this.requestData.suspendedEnd;
        if (!susStart && !susEnd) {
          return;
        }
        this.showStatus = sus + " from " + susStart + " to " + susEnd;
        // this.showStatus = `<strong>${sus} from</strong> ${susStart} <strong>to</strong> ${susEnd}`;
      }
    },
    handleCloseClick() {
      this.pageStatus = 0;
      if(this.$route.query.pageStatus == 2){
        this.requestData = {};
      }
      // this.$router.back();
      this.$router.push({ path: "/ship-vetting/index" });
      // fix back error
      setTimeout(() => {
        if (this.$route.path == "/ship-vetting/index" && this.$route.query.id) {
          this.$router.push({ path: "/ship-vetting/index" });
        }
      }, 500);
    },
    handleStatusButtonClick(status){
      // Suspended
      if (status == 10){
        if ((this.suspendedStartDate == null || this.suspendedEndDate == null)){
          this.$message.error("Start Date and End Date required");
          return;
        }
        if (new Date(this.suspendedEndDate) < new Date(this.suspendedStartDate)) {
            this.$message.error("End Date must be after Start Date");
            return;
        }
      }
      // More Info Remark mandatory
      if(status == 8 && (this.logisticsRemarks == null || this.logisticsRemarks.trim() == "")){
        this.$message.error("Remark required");
        return;
      }
      const params = {
        status: status,
        logisticsRemarks: this.logisticsRemarks,
        suspendedStartDate: this.suspendedStartDate,
        suspendedEndDate: this.suspendedEndDate,
      }
      this.$refs.shipVettingForm.updateRequest(params, () => {

      });
      this.confirmToSubmitDialog = false;
      this.resetLogisticsRemarksForm();
      this.resetSuspendedForm();

    },
    handleDelete(){
      if (this.requestData.shipStatus != 1){
        this.$message.error("Request status has been changed. Not allowed to delete.");
        return;
      }
      deleteShipVettingRequest(this.$route.query.id).then((res) => {
        if(res.code == 200){
          this.$message.success("Request deleted");
          this.handleCloseClick();
        }
      });
    },
    resetLogisticsRemarksForm(){
      // this.$refs.logiscticsRemarkForm.clearValidate();
      this.logisticsRemarksDialog= false;
      this.logisticsRemarks = "";
    },
    resetSuspendedForm(){
      this.suspendedDialog = false;
      this.suspendedStartDate = null;
      this.suspendedEndDate = null;
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>

<style scoped lang="scss">
.ship-detail{
  height: 100%
  // height: calc(100vh - 90px);
  // overflow: hidden;
}

</style>
