<template>
  <div class=""> 
    <div class="shipDoc mb-4">
      <!-- Header -->
      <div style="display: flex; align-items: center; gap: 10px;">
        <p class="docTitle">{{ componentName }} Document</p>
        <div style="flex-grow: 1; border-top: 1px solid #ddd;"></div>
        <input
          type="file"
          :id="componentId"
          multiple
          accept=".pdf"
          style="display: none;"
          @change="handleFileUpload"
        />
        <el-button size="mini" type="primary" plain class="upload-btn" @click="triggerFileUpload" :disabled="disabled">Upload Document</el-button>
      </div>

      <!-- File List -->
      <div class="flex-container">
        <div class="flex-item" v-if="oldFileList.length > 0" >
          <div
            class="file-card"
            v-for="(file, index) in oldFileList"
            :key="componentId + file.id + index"
            @click="viewFile(file)"
          >
            <span>
              <svg-icon class="pdf-icon" icon-class="pdf" />
            </span>
            <div class="file-info">
              <p class="file-name" :title="file.name">{{ file.originalFileName? file.originalFileName : getFileName(file.documentPath) }}</p>
              <p class="file-meta">Uploaded on:</p>
              <p class="file-meta">{{ file.createTime }}</p>
              <p class="file-meta">{{ file.createBy }}</p>
            </div>
            <el-button 
              class="remove-btn" 
              @click.stop="removeOldFile(index, file.id)" 
              v-if="!disabled"
              size="mini" 
              type="text" 
              icon="el-icon-delete"
            ></el-button>
          </div>
        </div>

        <div class="flex-item" v-if="fileList.length > 0">
          <div
            class="file-card"
            v-for="(file, index) in fileList"
            :key="'old' + componentId + file.id + index"
            @click="viewFile(file)"
          >
            <span>
              <svg-icon class="pdf-icon" icon-class="pdf" />
            </span>
            <div class="file-info">
              <p class="file-name" :title="file.name">{{ file.originalFileName? file.originalFileName : getFileName(file.documentPath) }}</p>
              <p class="file-meta">Uploaded on:</p>
              <p class="file-meta">{{ file.createTime }}</p>
              <p class="file-meta">{{ file.createBy }}</p>
            </div>
            <el-button 
              class="remove-btn" 
              @click.stop="removeFile(index, file.id)" 
              v-if="!disabled"
              size="mini" 
              type="text" 
              icon="el-icon-delete"
            ></el-button>
          </div>
        </div>

        <!-- No Files -->
        <span v-if="fileList.length == 0 && oldFileList.length == 0" class="nofiles" style="display: block; margin-top: 10px;">No Documents Uploaded</span>
      </div>
    </div>
    <pdf-view
      title="Ship Document"
      :pdfUrl="pdfUrl"
      v-model="showPdfView"
    />
  </div>
</template>
    
<script>
import { mapState } from "vuex";
import PdfView from "@/components/common/pdf-view.vue";
export default {
  name: "ShipVettingUploadDocument",
  components: { PdfView  },
  props: {
    componentName:"",
    componentId:"",
    disabled:false,
    uploadedFiles: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    uploadedFiles: {
      handler(newFiles) {
        this.fileList = [];
        this.validFiles2 = [];
        this.oldFileList = [];
        this.oldFileList = [...newFiles];
        // this.fileList = [...newFiles];
      },
      immediate: true,
    },
  },
  data() {
    return {
      oldFileList: [],
      fileList: [], // for ui
      eventFileList:[],
      showPdfView: false,
      pdfUrl: "",
      validFiles2: [], // for api
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getFileName(url){
      return url? url.split('/').pop() : "";
    },
    triggerFileUpload() {
      document.getElementById(this.componentId).click();
    },
    handleFileUpload(event) {
      const files = event.target.files;
      const validFiles = [];

      Array.from(files).forEach(file => {
        this.fileList.push({
          originalFileName: file.name,
          // id: file.id,
          raw: file,
          documentUrl: URL.createObjectURL(file),
          createBy: this.userInfo.nickName,
          createTime: new Date().toLocaleString(),
        });
        validFiles.push(file);
      });
      // this.validFiles2 = validFiles;
      // this.$emit('files-changed', validFiles);
      this.validFiles2.push(...validFiles);
      this.$emit('files-changed', this.validFiles2);
    },
    removeOldFile(index,id) {
      // remove from ui
      // this.fileList.splice(index, 1);

      // remove from api
      if(id){
        this.oldFileList.splice(index, 1);
        this.$emit('files-deleted',id);
      }
    },
    removeFile(index,id) {
      // remove from ui
      this.fileList.splice(index, 1);

      // remove from api
      this.validFiles2.splice(index,1);
      this.$emit('files-changed', this.validFiles2);
    },
    viewFile(file) {
      if (file.documentUrl) {
        // window.open(file.documentUrl, '_blank');
        // this.pdfUrl = file.documentUrl;
        this.pdfUrl = process.env.VUE_APP_BASE_API + file.documentUrl,
        this.showPdfView = true;
      } else {
        this.$message.warning('Unable to view the file');
      }
    },
  },
};
</script>
    
<style scoped lang="scss">
::v-deep .el-form--label-top .el-form-item__label{
  padding: 0;
}
::v-deep .el-form-item--mini .el-form-item__label {
  line-height: 20px;
}
.information {
  .divider-line {
    padding-bottom: 5px;
    border-bottom: 2px solid #e5e7ed;
  }
  .title {
    // color: #4c565c;
    // margin-left: 9px;
    // font-size: 14px;
  }
}
.nofiles{
  font-size: 12px;
  color: #606266;
  text-align: center;
  padding: 50px;
}
.docTitle {
  color: #606266;
  font-size: 14px;
}

.file-list {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}

.file-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 130px;
  height: 160px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  position: relative; /* For positioning delete button */
  text-align: center;
  cursor: pointer;
}

.pdf-icon {
  font-size: 36px;
  color: #0080ff;
  margin: 0 5px;
  width: 53px;
  height: 74px;
}

.file-info {
  cursor: pointer;
  text-align: center;
}

.file-name {
  margin: 5px 0 0;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}

.file-meta {
  margin: 0;
  font-size: 10px;
  color: #999;
}

.remove-btn {
  position: absolute; /* Positioning the remove button */
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: color 0.3s;
}

.remove-btn:hover {
  color: gray;
}

.flex-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}

.flex-item {
  text-align: center;
  display: flex;
}

</style>
