<template>
  <div class="top barging-container">
    <div class="flex flex-row justify-between p-1 laptop:pb-2 desktop:pb-4">
      <tabs :tabs="tabsList" @updateIndex="updateIndex" :currentIndex="upIndex"></tabs>
    </div>
    <div class="barging-card" style="padding:0px 0px; margin-top:0px; background-color:#f2f7fa; border-radius: 10px;">
      <!-- search bar multiple row in mobile view -->
      <div class="w-full searchBar" id="shipVettBar" ref="shipVettSearchBarRef" v-show="true">
        <div class="flex flex-wrap justify-end" >
          <div class="flex flex-row py-2 pr-2">
            <el-date-picker v-model="params.sireDate" size="mini" 
              start-placeholder="Sire Start Date"
              end-placeholder="Sire End Date"
              prefix-icon="el-icon-date" 
              type="daterange" 
              :style="{ width: '250px' }"
              format="yyyy-MM-dd" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <div class="flex flex-row py-2 pr-2">
            <el-date-picker v-model="params.utDate" size="mini"  
              start-placeholder="UT Start Date"
              end-placeholder="UT End Date"
              prefix-icon="el-icon-date" 
              type="daterange" 
              :style="{ width: '250px' }"
              format="yyyy-MM-dd" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <div class="flex flex-row py-2 pr-2">
            <el-input v-model="params.searchValue" size="mini" clearable placeholder="Search IMO/Name" :style="{ width: '100%' }"></el-input>
          </div>
          <div class="flex flex-row pl-2 py-2">
            <el-button size="mini" type="primary" @click="getList">Search</el-button>
            <el-button size="mini" type="primary" v-if="userInfo.role != 13" @click="handleCreateNewShip">Create a New Ship</el-button>
          </div>
        </div>
      </div>

      <!-- search bar inline -->
      <!-- <div class="w-full searchBar" v-show="false">
        <div class="" ref="searchBarRef"
          style="display: flex;flex-direction: row-reverse;justify-content: flex-start;overflow: auto;">
          <div class="flex flex-row pl-2 py-2">
            <el-button size="mini" type="primary" @click="getList">Search</el-button>
            <el-button size="mini" type="primary" v-if="userInfo.role != 13" @click="handleCreateNewShip">Create a New Ship</el-button>
          </div>
          <div class="flex flex-row py-2 pr-2">
            <el-input v-model="params.searchValue" size="mini" clearable placeholder="Search IMO/Name" :style="{ width: '150px' }"></el-input>
          </div>
          <div class="flex flex-row py-2 pr-2">
            <el-date-picker v-model="params.utDate" size="mini"  
              start-placeholder="UT Start Date"
              end-placeholder="UT End Date"
              prefix-icon="el-icon-date" 
              type="daterange" 
              :style="{ width: '300px' }"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="flex flex-row py-2 pr-2">
            <el-date-picker v-model="params.sireDate" size="mini" 
              start-placeholder="Sire Start Date"
              end-placeholder="Sire End Date"
              prefix-icon="el-icon-date" 
              type="daterange" 
              :style="{ width: '300px' }"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
        </div>
      </div> -->

      <!-- table -->
      <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">
        <div class="w-full Comtable" id="shipVettTable" ref="shipVettTableRef">
          <el-table key="shipVettingList" 
            :data="listData"
            :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            }"
            :cell-style="getCellStyle"
            @row-click="handleTableRowClick"
            table-layout="auto"
            height="100%"
          >
            <el-table-column align="left" prop="imo" label="IMO" min-width="100"></el-table-column>
            <el-table-column align="left" prop="shipName" label="Ship Name" min-width="200"></el-table-column>
            <el-table-column align="left" prop="age" label="Age" min-width="80"></el-table-column>
            <el-table-column align="left" prop="sireDocumentDate" label="SIRE Document Date" min-width="150"></el-table-column>
            <el-table-column align="left" prop="utObservationDate" label="UT Observation Date" min-width="150" :formatter="formatCompleteTime"></el-table-column>
            <el-table-column align="left" prop="shipStatus" label="Ship Status" min-width="200">
              <template slot-scope="scope">
                <div :style="getShipStatusStyle(scope.row.shipStatus)">
                  {{ getShipVettingStatus(scope.row.shipStatus) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="left" prop="createBy" label="Created by" min-width="150" v-if="userInfo.role == 1 || userInfo.role == 13"></el-table-column>
            <el-table-column align="left" prop="action" label="Action" min-width="850" v-if="userInfo.role == 1">
              <template slot-scope="scope">
                <el-button size="mini" type="success" plain v-if="[1,3].includes(scope.row.shipStatus)" @click.stop="handleStatusButtonClick(scope.row.id,6)">Accept</el-button>
                <el-button size="mini" type="danger"  plain v-if="[1,3].includes(scope.row.shipStatus)" @click.stop="handleStatusButtonClick(scope.row.id,5)">Reject</el-button>
                <el-button size="mini" type="primary" plain v-if="[1].includes(scope.row.shipStatus)" @click.stop="handleStatusButtonClick(scope.row.id,2)">Submit for Inspection</el-button>
                <el-button size="mini" type="primary" plain v-if="[1,3].includes(scope.row.shipStatus)" @click.stop="handleStatusButtonClick(scope.row.id,4)">Anchorage Inspection</el-button>
                <el-button size="mini" type="primary" plain v-if="[1,3].includes(scope.row.shipStatus)" @click.stop="handleMoreInfoRequired(scope.row.id,8)">More Information Required</el-button>
                <el-button size="mini" type="primary" plain v-if="[1,3].includes(scope.row.shipStatus)" @click.stop="handleSuspended(scope.row.id,10)">Suspend</el-button>
                <!-- <el-button size="mini" type="primary" plain v-if="[1].includes(scope.row.shipStatus)" @click.stop="handleDelete(scope.row.id)">Delete</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="barging-pagination">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          :pageSizes="[20, 30, 50, 100]"
          @pagination="getList"
        ></pagination>
      </div>
    </div> 
    <el-dialog title="More Information Required" :visible.sync="logisticsRemarksDialog" :width="isMobileScreen ? '100%' : '500px'" @click="resetLogisticsRemarksForm()">
      <div>
        <el-form label-width="auto" size="mini" label-position="top" ref="remarksForm">
          <el-form-item label="Please state the remark:" prop="logisticsRemarks" >
            <el-input type="textarea" :autosize="{ minRows: 3}" v-model="logisticsRemarks" clearable size="small" :style="{ width: '100%' }" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="resetLogisticsRemarksForm()">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(selectedRowID,8)">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog title="Suspend Ship" :visible.sync="suspendedDialog" :width="isMobileScreen ? '100%' : '500px'" @close="resetSuspendedForm()">
      <div>
        <el-form label-width="auto" size="mini" label-position="left" ref="suspendedForm">
          <el-form-item label="Start Date" prop="suspendedStartDate" >
            <el-date-picker
            v-model="suspendedStartDate" 
            placeholder="Start Date"
            type="datetime" 
            format="yyyy-MM-dd HH:mm:ss" 
            value-format="yyyy-MM-dd HH:mm:ss"
             :style="{ width: isMobileScreen ? '100%' : '300px' }"
            clearable/>
          </el-form-item>
          <el-form-item label="End Date" prop="suspendedEndDate" >
            <el-date-picker 
            v-model="suspendedEndDate" 
            placeholder="End Date"
            type="datetime" 
            format="yyyy-MM-dd HH:mm:ss" 
            value-format="yyyy-MM-dd HH:mm:ss"
            default-time="23:59:59"
            :style="{ width: isMobileScreen ? '100%' : '300px' }"
            clearable/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="resetSuspendedForm()">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(selectedRowID,10)">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
  
<script>
import tabs from "@/components/tabs.vue";
import { getShipVettingListCount, getShipVettingList, updateShipVettingRequestStatus } from "@/services";
import PdfView from "@/components/common/pdf-view.vue";
import { mapState } from "vuex";
import { getShipVettingStatus } from "@/utils/constants";
import userInfo from "@/mixins/userInfo";
export default {
  name: "ShipVettingList",
  components: { tabs, PdfView },
  data() {
    return {
      pageStatus: 0, //for routing
      tabsList: [],
      upIndex: 0, // for request status
      listData: [],
      searchValue: "",
      selectDate: "",
      pageParams: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      startDate: "",
      endDate: "",
      params: {
        pageNum: 1,
        pageSize: 20,
        searchValue: null,
        sireDate: null,
        utDate: null,
      },
      logisticsRemarksDialog: false,
      logisticsRemarks: "",
      selectedRowID: "",
      suspendedDialog: false,
      suspendedStartDate: null,
      suspendedEndDate: null,
    };
  },
  computed: {
    ...mapState(["locale","userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.orderListRef) {
      this.$refs.orderListRef.handleStopRefresh()
    }
    next()
  },
  created() {
    // logistic 1, customer 5, customer admin 4, inspector 13
    this.upIndex = window.localStorage.getItem('upIndex')||0;
    this.getList();
  },
  mounted() {
    this.adjustHeight();
    window.addEventListener('resize', this.adjustHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustHeight);
  },
  methods: {
    adjustHeight() {
      const referenceElement = document.getElementById("shipVettBar");
      const targetElement = document.getElementById("shipVettTable");
      const mobileLayout = window.innerWidth < 1025;
      const fullscreenLayout = window.innerWidth > 1440;

      if (referenceElement && targetElement) {
        const referenceHeight = referenceElement.offsetHeight;
        if (referenceHeight === 44) {
          targetElement.style.height = "calc(100vh - 220px)";
          if(fullscreenLayout){
            targetElement.style.height = "calc(100vh - 230px)";
          }
        } else if (referenceHeight >= 80 && referenceHeight <= 90) {
          if (mobileLayout) {
            targetElement.style.height = "calc(100vh - 300px)";
          } else {
            targetElement.style.height = "calc(100vh - 280px)";
          }
        } else if (referenceHeight === 132) {
          targetElement.style.height = "calc(100vh - 340px)";
        } else if (referenceHeight === 176) {
          targetElement.style.height = "calc(100vh - 380px)";
        } else {
          // targetElement.style.height = "auto";
        }
      }
    },
    updateIndex(index) {
      this.upIndex = index;
      this.params.pageNum = 1;
      window.localStorage.setItem('upIndex',index)
      this.getList();
    },
    handleSelectChange() {
      this.getList();
    },
    getList() {
      let data = {
        ...this.params,
        shipStatus: this.upIndex == 0? "" : Number(this.upIndex), // for request status
        
      };
      if (this.params.utDate){
        data.startDateUt = this.params.utDate[0];
        data.endDateUt = this.params.utDate[1];
        delete data.utDate;
      }
      if (this.params.sireDate){
        data.startDateSire = this.params.sireDate[0];
        data.endDateSire = this.params.sireDate[1];
        delete data.sireDate;
      }
      getShipVettingListCount().then((res) => {
        if(res.code == 200){
          this.tabsList = [
            { label: "All", count: res.data.all, index: "0" },
            { label: "Pending Logistic", count: res.data.pendingLogistics || 0, index: "1" },
            // { label: "Pending Inspector", count: res.data.pendingInspection || 0, index: "2" },
            // { label: "Inspector Responded", count: res.data.inspectorResponded || 0, index: "3" },
            { label: "Accepted", count: res.data.approved || 0, index: "6" },
            { label: "Rejected", count: res.data.rejected || 0, index: "5" },
            { label: "Anchorage Inspection", count: res.data.anchorageInspection || 0, index: "4" },
            { label: "More Info Required", count: res.data.moreInfoRequired || 0, index: "8" },
            { label: "Pending Observation", count: res.data.pendingObservation || 0, index: "7" },
            { label: "Expired", count: res.data.expired || 0, index: "9" },
            { label: "Suspended", count: res.data.suspended || 0, index: "10" },
          ];

          // Customer cant view status below
          if(this.userInfo.role === '1' || this.userInfo.role === '13'){
            this.tabsList.splice(2, 0, 
              { label: "Pending Inspector", count: res.data.pendingInspection || 0, index: "2" },
              { label: "Inspector Responded", count: res.data.inspectorResponded || 0, index: "3" }
            );
          }          
        }
      });
      getShipVettingList(data).then((res) => {
        this.total = res.total;
        this.listData = res.rows;
      });
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    getShipVettingStatus,
    getShipStatusStyle(status) {
      const statusColors = {
        5: { color: "red" }, 
        6: { color: "green" }, 
      };
      return statusColors[status] || { color: "" };
    },
    handleTableRowClick(row, column, event) {
      this.$router.push({
        path: "/ship-vetting/index",
        query: {
          id: row.id,
          pageStatus: 1,
        },
      });
      sessionStorage.setItem("pageStatus", 1);
      return;
    },
    handleCreateNewShip(){
      this.$router.push({
        path: "/ship-vetting/index",
        query: {
          // id: 1,
          pageStatus: 2,
        },
      });
      sessionStorage.setItem("pageStatus", 2);
      return;
    },
    handleStatusButtonClick(id,status){
      // Suspended
      if (status == 10){
        if ((this.suspendedStartDate == null || this.suspendedEndDate == null)){
          this.$message.error("Start Date and End Date required");
          return;
        }
        if (new Date(this.suspendedEndDate) < new Date(this.suspendedStartDate)) {
            this.$message.error("End Date must be after Start Date");
            return;
        }
      }
      // More Info Remark mandatory
      if(status == 8 && (this.logisticsRemarks == null || this.logisticsRemarks.trim() == "")){
        this.$message.error("Remark required");
        return;
      }
      const params = { shipStatus: status }
      if(status == 8){
        params.remarks = this.logisticsRemarks;
      }
      if(status == 10){
        params.suspendedStart = this.suspendedStartDate, 
        params.suspendedEnd = this.suspendedEndDate
      }
      updateShipVettingRequestStatus(id,params).then((res) => {
        if(res.code == 200){
          this.$message.success("Request status updated");
          this.getList();
        }
      });
      this.resetLogisticsRemarksForm();
      this.resetSuspendedForm();

    },
    handleMoreInfoRequired(id,status){
      this.selectedRowID = id;
      this.logisticsRemarks = "";
      this.logisticsRemarksDialog = true;
    },
    handleDelete(id){

    },
    resetLogisticsRemarksForm(){
      this.selectedRowID = null;
      this.logisticsRemarks = "";
      this.logisticsRemarksDialog= false;
    },
    handleSuspended(id,status){
      this.selectedRowID = id;
      this.suspendedStartDate = null;
      this.suspendedEndDate = null;
      this.suspendedDialog = true;
    },
    resetSuspendedForm(){
      this.suspendedDialog = false;
      this.suspendedStartDate = null;
      this.suspendedEndDate = null;
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .pagination-container {
  background: #f2f7fa;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .el-table .cell {
  word-break:normal;
}

// @media (max-width: 1023px) {
//   .Comtable {
//     height: calc(100vh - 265px);
//   }
// }
// @media (min-width: 1024px) {
//   .Comtable {
//     height: calc(100vh - 14.5rem);
//   }
//   .barging-card{
//     margin-top: 0px !important;
//     height: calc(100vh - 150px);
//   }
// }
// @media (min-width: 1441px) {
//   .barging-card{
//     height: calc(100vh - 160px);
//   }
//   .Comtable {
//     height: calc(100vh - 15rem);
//   }
// }
// @media (max-width: 867px) {
//   .Comtable {
//     height: calc(100vh - 20rem);
//   }
// }
// .mobile-view{
//   .Comtable {
//     height: calc(100vh - 25rem);
//   }
// }
.searchBar{
  width: 100% !important;
  background-color: #f3f7fa;
  // border-radius: 10px;
  // border: 1px solid #D5DDED;
  font-size: 11px;
  color: #1D1B20;
  font-family: 'Roboto';
  padding: 0px 20px 0px 20px;

  .label {
    padding: 5px;
    color: #1D1B20;
    font-weight: 600;
    text-align: right;
    width: 90px;
  }
}
.barging-container {
  border-radius: 10px;
}
</style>
