<template>
  <div class="ship-info">
    <div class="flex flex-col"> 
      <el-form ref="shipInfoForm" size="mini" label-position="top" :model="shipData" :inline="true">
        <el-form-item label="IMO No." required>
          <el-input size="mini" v-model="shipData.imo" :disabled="imoDisabled" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="IMO No." required>
          <el-select 
            v-model="shipData.imo"
            class="bargeNameinput"
            size="mini"
            clearable 
            placeholder="Search IMO" filterable bargeNameloading remote
            :remote-method="handleSearchBarge"
            @change="handleSelectedIMO"
            :loading="isBargeOptionsLoading"
            :disabled="shipInfoDisabled"
            v-cancel-read-only>
            <el-option v-for="item in bargeoptions" :key="item.value" :disabled="item.unFlag == 1" :value="item.imo">
              <div style="display: flex; justify-content: space-between; width: 100%;">
                <span>{{ item.bargeName ? `${item.imo} (${item.bargeName})` : item.imo }}</span>
                <span :style="{ marginLeft: 'auto', color: (item.requestStatus || '').toLowerCase() === 'accepted' ? 'green' : 'gray' }">{{ item.requestStatus }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="Name" prop="name">
          <el-input size="mini" v-model="shipData.shipName" :disabled="shipInfoDisabled" clearable></el-input>
        </el-form-item>
        <el-form-item label="Built Date" prop="builtDate">
          <el-date-picker 
            v-model="shipData.buildDate" 
            placeholder="Built Date"
            type="date" 
            format="yyyy-MM-dd" 
            value-format="yyyy-MM-dd"
            :disabled="shipInfoDisabled"
            :picker-options="pickerOptions"
            clearable/>
        </el-form-item>
        <el-form-item label="Dead Weight" prop="deadWeight">
          <el-input size="mini" v-model="shipData.deadWeight" type="number" :disabled="shipInfoDisabled" clearable></el-input>
        </el-form-item>
        <el-form-item label="Flag" prop="flag">
          <el-input size="mini" v-model="shipData.flag" :disabled="shipInfoDisabled" clearable></el-input>
        </el-form-item>
        <el-form-item label="Loa" prop="loa">
          <el-input size="mini" v-model="shipData.loa" :disabled="shipInfoDisabled" clearable></el-input>
        </el-form-item>
      </el-form>
      <ShipVettingUploadDocument 
        class="mt-2"
        :componentName="'Ship'" 
        :componentId="'shipDoc'" 
        :uploadedFiles="shipFilesFromApi"
        :disabled="shipDocumentDisabled"
        @files-changed="updateShipFiles"
        @files-deleted="deleteShipFiles"
      />

      <div class="information mt-4">
        <div class="flex justify-between divider-line mb-1">
          <p class="">Inspection</p>
          <el-button size="mini" type="primary" plain @click="addInspection" v-if="shipData.shipStatus == 4 && userInfo.role == 13">Add Inspection</el-button>
        </div>
        <div v-for="(inspection, index) in inspections" :key="inspection.id || index" class="inspectionContainer">
          <div class="flex justify-between items-center mb-4" v-if="index != 0">
            <div class="divider-line" style="flex-grow: 1; margin-right: 10px;"></div>
            <el-button 
              size="mini" 
              type="danger" 
              plain 
              @click="removeInspection(index)" 
              v-if="inspection.isNew"
              >
              Remove
            </el-button>
          </div>
          <el-form
            ref="inspectionForm"
            size="mini"
            label-position="top"
            :model="inspection"
            :inline="false"
          >
            <el-form-item label="SIRE Document Date" prop="inspectionDate"  :label="index === 0 ? 'SIRE Document Date' : 'Inspection Date'" >
              <el-date-picker
                v-model="inspection.inspectionDate"
                :placeholder="index === 0 ? 'SIRE Document Date' : 'Inspection Date'"
                type="date" 
                format="yyyy-MM-dd" 
                value-format="yyyy-MM-dd"
                clearable
                :disabled="inspectionDisabled(index,inspection.isNew)"
              />
            </el-form-item>
            <el-form-item v-if="index === 0" label="UT Observation Date" prop="utObservationDate">
              <el-date-picker 
                v-model="shipData.utObservationDate" 
                placeholder="UT Observation Date"
                type="date" 
                format="yyyy-MM-dd" 
                value-format="yyyy-MM-dd"
                :disabled="utDateDisabled"
                clearable/>
            </el-form-item>
            <el-form-item label="Remarks" required>
              <el-input 
                size="mini" 
                v-model="inspection.inspectionRemarks" 
                type="textarea" 
                :autosize="{ minRows: 3}"
                style="width: 50%"
                clearable 
                :disabled="inspectionDisabled(index,inspection.isNew)" 
              />
            </el-form-item>
          </el-form>
          <ShipVettingUploadDocument
            :componentName="'Inspector'"
            :componentId="'inspectorDoc-' + index"
            :disabled="inspectionDisabled(index,inspection.isNew)"
            :uploadedFiles="inspections[index].oldDocuments"
            @files-changed="updateInspectionFiles(index, $event)"
            @files-deleted="deleteInspectionFiles(index, $event)"
          />
        </div>
      </div>

    
    </div>
    <success-dialog v-on="$listeners" ref="successDialog" />
  </div> 
</template>
    
<script>
import { mapState } from "vuex";
import ShipVettingUploadDocument from './upload.vue'
import { getShipVettingStatus } from "@/utils/constants";
import userInfo from "@/mixins/userInfo";
// import SuccessDialog from "./success-dialog.vue";
import SuccessDialog from "@/components/common/success-dialog.vue";
import { addNewShipVettingRequest,
          updateShipVettingRequestData,
          updateShipVettingRequestStatus,
          uploadShipVettingShipDocument,
          updateShipVettingInspection } from "@/services";
import { searchBarge } from '@/services/user';

class Inspection {
  constructor() {
    this.id = null;
    this.oldDocuments = [];
    this.inspectionDate = null;
    this.inspectionRemarks = null;
    this.documents = [];
    this.deletedIds = [];
    this.isNew = true;
  }
}
export default {
  name: "ShipVettingInformation",
  components: { ShipVettingUploadDocument, SuccessDialog },
  props: {
    imo: {
      default: null,
    },
    shipName: {
      default: null,
    },
    requestData:{
      default: null,
    }
  },
  watch:{
    requestData(val){
      this.getList();
    }
  },
  data() {
    return {
      shipData: {
        imo: "",
        shipName: "",
        buildDate: "",
        deadWeight: "",
        flag: "",
        loa: "",
        // utObservationDate: "",
        shipStatus: "",
      },
      shipFiles: [],
      shipFilesFromApi: [],
      shipFilesDeleted: [],
      inspectionObj: {
        id: null,
        oldDocuments: [],
        inspectionDate: null,
        inspectionRemarks: "",
        documents: [],
        deletedIds: [],
        isNew: true,
      },
      inspections: [
        
      ],
      inspectionsFromApi:[],
      deletedFilesId:[],
      rid: "",
      pickerOptions:{
        disabledDate(date){
          const currentYear = new Date().getFullYear();
          return date.getFullYear() > currentYear;
        }
      },
      isBargeOptionsLoading: true,
      bargeoptions: [],
      // rules: {
      //   imo: [
      //     { required: true, message: "IMO is required", trigger: "blur" },
      //   ],
      //   inspectorRemarks: [
      //     { required: true, message: "Remarks is required", trigger: "blur" },
      //   ]
      // },
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
    shipInfoDisabled(){
      const requestStatus = this.requestData.shipStatus;
      const pageStatus = this.$route.query.pageStatus;
      const isLogistics = this.userInfo.role == 1;
      const isCustomer = this.userInfo.role == 4 || this.userInfo.role == 5;
      const isInspector = this.userInfo.role == 13;

      if(pageStatus == 2){
        return false;
      }
      if(isLogistics && (requestStatus == 1 || requestStatus == 3)){
        return false;
      }
      if(isCustomer && (requestStatus == 1 || requestStatus == 7 || requestStatus == 8)){
        return false;
      }
      if(isInspector && (requestStatus == 2 || requestStatus == 4 )){
        return false;
      }
      return true;
    },
    imoDisabled(){
      const pageStatus = this.$route.query.pageStatus ?? 0;
      if(pageStatus == 2){
        return false;
      }
      return true;
    },
    utDateDisabled(){
      if(this.userInfo.role == 13 
          && (this.requestData.shipStatus == 2 || this.requestData.shipStatus == 4)){
        return false;
      }
      return true;
    },
    shipDocumentDisabled(){
      const requestStatus = this.requestData.shipStatus;
      const pageStatus = this.$route.query.pageStatus;
      const userRole = this.userInfo.role;
      const isLogistics = this.userInfo.role == 1;
      const isCustomer = this.userInfo.role == 4 || this.userInfo.role == 5;
      const isInspector = this.userInfo.role == 13;

      if(pageStatus == 2){
        return false;
      }
      if(isLogistics && (requestStatus == 1 || requestStatus == 3)){
        return false;
      }
      if(isCustomer && (requestStatus == 1 || requestStatus == 7 || requestStatus == 8)){
        return false;
      }
      return true;
    },
    inspectionDisabled(){
      return function (index,isNew) {
        if(this.userInfo.role == 13 && (this.requestData.shipStatus == 2 || this.requestData.shipStatus == 4)){
          if(index == 0){
            return false;
          }
          if(isNew){
            return false
          }

          return true;
        }
        return true;
      };
      
    }
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    getShipVettingStatus,
    getList() {
      this.shipFiles = [];
      this.shipFilesDeleted = [];
      this.inspections = [new Inspection()];

      if(this.requestData && this.$route.query.pageStatus != 2){
        this.shipData = { ...this.requestData };

        if(this.shipData && this.shipData.customerDocuments && this.shipData.customerDocuments.length > 0){
          this.shipFilesFromApi = this.shipData.customerDocuments.map(doc => ({
            ...doc
          }));
        }

        if (this.shipData && this.shipData.inspections && this.shipData.inspections.length > 0) {
          this.inspections = this.shipData.inspections.map(ins => ({
            id: ins.id,
            inspectionDate: ins.inspectionDate,
            inspectionRemarks: ins.inspectionRemarks,
            oldDocuments: ins.documents,
            documents: [],
            deletedIds: [],
            isNew: false,
          }));
        }
        this.inspections[0].inspectionDate = this.shipData.sireDocumentDate;
        this.inspections[0].inspectionRemarks = this.shipData.inspectorRemarks;
      }
    },
    async handleSearchBarge(query) {
      if (query !== '') {
        this.isBargeOptionsLoading = true;
        try {
          const res = await searchBarge(query);
          this.bargeoptions = res.data;
          // this.bargeoptions = res.data.map(barge => ({
          //     ...barge,
          //     requestStatus: "Re"
          // }));
        } catch (error) {
          this.bargeoptions = [];
        } finally {
          this.isBargeOptionsLoading = false;
        }
      } else {
        this.bargeoptions = [];
      }
    },
    async handleSelectedIMO(selectedIMO) {
      const selectedBarge = this.bargeoptions.find(item => item.imo === selectedIMO);
      this.shipData.shipName = selectedBarge ? selectedBarge.bargeName || "" : "";
    },
    updateShipFiles(files) {
      this.shipFiles = files;
    },
    deleteShipFiles(id){
      if(id){
        this.shipFilesDeleted.push(id)
      }
    },
    addInspection() {
      this.inspections.push(new Inspection());
    },
    removeInspection(index) {
      if (this.inspections[index]?.isNew) {
        this.inspections.splice(index, 1);
      }
    },
    updateInspectionFiles(index, files) {
      this.inspections[index].documents = files;
    },
    deleteInspectionFiles(index,id){
      if(id){
        this.inspections[index].deletedIds.push(id);
      }
    },
    async updateRequest(params,callback) {
      const requestStatus = this.shipData.shipStatus;
      const isLogistics = this.userInfo.role == 1;
      const isCustomer = this.userInfo.role == 4 || this.userInfo.role == 5;
      const isInspector = this.userInfo.role == 13;
      const btnStatus = params.status;
      const logisticsRemarks = params.logisticsRemarks;
      const suspendedStartDate = params.suspendedStartDate;
      const suspendedEndDate = params.suspendedEndDate;

      const data = {
        imo: this.shipData.imo,
        shipName: this.shipData.shipName,
        buildDate: this.shipData.buildDate,
        deadWeight: this.shipData.deadWeight,
        flag: this.shipData.flag,
        loa: this.shipData.loa,
        utObservationDate: this.shipData.utObservationDate,
      };
      data.deadWeight = data.deadWeight === "" ? null : parseFloat(data.deadWeight);
      if (!data.imo) {
        this.$message.error("IMO number required");
        return;
      }

      // 2025-02-04 remove the mandatory at least 1 ship doc / 1 new doc
      // Ship Doc: Check if all old files are deleted and no new files uploaded
      const remainingOldFiles = this.shipFilesFromApi.filter(
        (file) => !this.shipFilesDeleted.includes(file.id)
      );
      // if (remainingOldFiles.length === 0 && this.shipFiles.length === 0) {
      //   this.$message.error("At least one Ship Document required");
      //   return;
      // }
      // if (isCustomer) {
      //   if (this.shipFiles.length === 0 && (requestStatus == 7 || requestStatus == 8)) {
      //     this.$message.error("At least one new document required");
      //     return;
      //   }
      // }

      // check inspection
      if (isInspector){
        data.sireDocumentDate = this.inspections[0].inspectionDate;
        data.inspectorRemarks = this.inspections[0].inspectionRemarks;

        if (requestStatus == 2){
          if (data.inspectorRemarks == null || data.inspectorRemarks.trim() == ""){
            this.$message.error("Inspection Remarks required");
            return;
          }
        }

        if (requestStatus == 4) {
          // Check if none of the inspections are new
          const allInspectionsNotNew = this.inspections.every(inspection => !inspection.isNew);
          if (allInspectionsNotNew) {
            this.$message.error("At least one new Inspection required");
            return;
          }

          // remarks
          const allRemarksFilled = this.inspections.every((inspection) => {
            return inspection.inspectionRemarks && inspection.inspectionRemarks.trim() !== "";
          });
          if (!allRemarksFilled) {
            this.$message.error("All Inspection Remarks required");
            return;
          }

          // files
          let allOldFilesDeleted = true;
          let noNewFilesUploaded = true;
          this.inspections.forEach((inspection) => {
            if (inspection.oldDocuments.length > 0) {
              allOldFilesDeleted = false;
            }
            if (inspection.documents.length > 0) {
              noNewFilesUploaded = false;
            }
          });
          if (allOldFilesDeleted && noNewFilesUploaded) {
            this.$message.error("At least one Inspector Document required");
            return;
          }
        }
      }

      try {
        // Add new request
        if (this.$route.query.pageStatus == 2) {
          if (isLogistics) {
            data.shipStatus = btnStatus;
            if(btnStatus == 8){
              data.remarks = logisticsRemarks
            }
            if(btnStatus == 10){
              data.suspendedStart = suspendedStartDate
              data.suspendedEnd = suspendedEndDate
            }
          }
          const res = await addNewShipVettingRequest(data, this.shipFiles);
          if (res.code === 200) {
            this.$refs.successDialog.open();
            this.$refs.successDialog.message = "New ship vetting request created";
            this.$refs.successDialog.shipVettingId = res.data.requestId;
          }
          return;
        }

        // Logistics Update
        if (isLogistics) {
          const res1 = await updateShipVettingRequestData(this.shipData.id, data);
          if (res1.code === 200 && btnStatus === 1) {
            this.$message.success("Request detail updated");
          }

          if (this.shipFiles.length !== 0 || this.shipFilesDeleted.length !== 0) {
            const res2 = await uploadShipVettingShipDocument(
              this.shipData.id,
              this.shipFiles,
              this.shipFilesDeleted
            );
            if (res2.code === 200) {
              this.$message.success("Ship Document uploaded");
            }
          }

          const params = { shipStatus: btnStatus,  };
          if(btnStatus == 8){
            params.remarks = logisticsRemarks;
          }
          if(btnStatus == 10){
            params.suspendedStart = suspendedStartDate
            params.suspendedEnd = suspendedEndDate
          }
          const res3 = await updateShipVettingRequestStatus(this.$route.query.id, params);
          if (res3.code === 200) {
            this.$message.success("Request status updated");
          }
        }

        // Customer Update
        if (isCustomer) {
          const res1 = await updateShipVettingRequestData(this.shipData.id, data);
          if (res1.code === 200) {
            this.$message.success("Request detail updated");
          }

          if (this.shipFiles.length !== 0 || this.shipFilesDeleted.length !== 0) {
            const res2 = await uploadShipVettingShipDocument(
              this.shipData.id,
              this.shipFiles,
              this.shipFilesDeleted
            );
            if (res2.code === 200) {
              this.$message.success("Ship Document uploaded");
            }
          }
        }

        // Inspector Update
        if (isInspector) {
          // the submitted inspection set cannot be modified
          this.inspections = this.inspections.filter((inspection, index) => {
            return index === 0 || inspection.id == null;
          });

          // the first set can be modified
          if (this.inspections[0].deletedIds.length == 0 && this.inspections[0].documents.length == 0){
            this.inspections.shift();
          }else{
            this.inspections[0].inspectionDate = null;
            this.inspections[0].inspectionRemarks = null;
          }

          const res1 = await updateShipVettingRequestData(this.shipData.id, data);
          if (res1.code === 200 && btnStatus === 1) {
            this.$message.success("Request detail updated");
          }

          for (const inspection of this.inspections) {
            const res2 = await updateShipVettingInspection(this.shipData.id, inspection);
            if (res2.code === 200) {
              this.$message.success("Inspection detail updated");
            }
          }
        }
      } catch (error) {
        // console.error(error);
        // this.$message.error("An error occurred while processing the request.");
      } finally {
        this.$emit("get-list");
      }
    },
    redirectToUpdatedPage(id){
      this.$router.replace({
        path: "/ship-vetting/index",
        query: {
          id: id,
          pageStatus: 1,
        },
      });
      sessionStorage.setItem("pageStatus", 1);
      this.$router.go(0);
      return;
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>
    
<style scoped lang="scss">
.ship-info{
  height: 100%;
}
::v-deep .el-form--label-top .el-form-item__label{
  padding: 0;
}
::v-deep .el-form-item--mini .el-form-item__label {
  line-height: 20px;
}
::v-deep .el-form-item--mini{
  margin-bottom: 0px;
}
::v-deep .el-form-item{
  margin-bottom: 10px;
}

.information {
  // font-size: 12px;

  .divider-line {
    padding-bottom: 5px;
    border-bottom: 2px solid #e5e7ed;
  }
  .title {
    // color: #4c565c;
    // margin-left: 9px;
    // font-size: 14px;
  }
}
.content{
  font-size: 12px;
  color: #606266;
}
.docTitle {
  color: #606266;
  font-size: 14px;
}

::v-deep .el-tabs--card {
  height: calc(100vh - 110px);
}
::v-deep .el-tab-pane  {
  height: calc(100vh - 110px);
  overflow-y: auto;
}
::v-deep .el-tab__content  {
  height: calc(100vh - 110px);
  overflow-y: auto;
}
.inspectionContainer {
  // border-radius: 10px;
  // padding-bottom: 10px;
  // border-bottom: 1px solid #e5e7ed;
}
::v-deep.bargeNameinput {
  width: 100%;

  .el-input__inner {
    color: #2e5efd !important;
  }
}
::v-deep .el-textarea.is-disabled .el-textarea__inner{
  color: #4e565c !important;
}
</style>
    